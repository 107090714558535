<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <create-order-form
        use-origin-office-warehouse
        with-destination-area
        :with-restock-type="false"
        with-upline
        v-model="order"
      />

      <template v-if="order.destinationOffice.id">
        <create-order-detail-form
          :order-id="order.id"
          :origin-office="{
            id: order.originOffice.id,
            code: order.originOffice.code,
          }"
          :destination-office="{
            id: order.destinationOffice.id,
            code: order.destinationOffice.code,
          }"
          :origin-warehouse="{
            id: order.originOffice.currentWarehouse.id,
            code: order.originOffice.currentWarehouse.code,
          }"
          :buyer-type="order.buyerType"
          :area="order.area"
          :columns="[
            'code',
            'name',
            'point',
            'stock',
            'qty',
            'price',
            'total_price',
            'action',
            'total_weight',
            'point_value',
            'bonus_value',
          ]"
          total-price-label=""
          :create-order-attributes="{
            order_shipment: order.shipment_type,
            order_type: 'sale',
            destination_warehouse_id:
              order.destinationOffice.currentWarehouse.id,
            ...(order.custom_shipment_address
              ? {
                  destination_address: {
                    province_id: order.shipmentAddress.province.id,
                    city_id: order.shipmentAddress.city.id,
                    district_id: order.shipmentAddress.district.id,
                    village_id: order.shipmentAddress.village.id,
                    address: order.shipmentAddress.address,
                  },
                }
              : {}),
          }"
          v-model="orderDetails"
          @order-created="onOrderCreated"
        />

        <create-payment-form
          :order-id="order.id"
          :payment-id="paymentId"
          :with-validate-button="false"
          :with-summary="false"
          sync
          auto-save
          v-model="paymentMethods"
        />

        <div class="flex justify-end gap-2">
          <base-button
            :disabled="totalPrice < 1"
            @click="visibleValidate = true"
            >Siap Validasi</base-button
          >
          <base-button color="danger" :to="{ name: 'transaksi-cashbill' }"
            >Selesai</base-button
          >
        </div>
      </template>

      <validate-order-confirmation
        :order-id="order.id"
        :visible="visibleValidate"
        @close="visibleValidate = false"
        @confirmed="visibleDetail = true"
      />
      <view-order-modal
        label="Cashbill"
        :columns="[
          'code',
          'warehouse',
          'office',
          'date',
          'user',
          'area',
          'shipment_type',
          'address',
        ]"
        :with-shipment="false"
        :visible="visibleDetail"
        :order-id="order.id"
        @close="$router.push({ name: 'transaksi-cashbill' })"
        @validated="$router.push({ name: 'transaksi-cashbill' })"
      />
    </div>
  </base-wrapper>
</template>

<script>
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import CreatePaymentForm from '@/components/payment/create-payment-form.vue';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import { toCurrency } from '@/services/currency.service';

export default {
  mixins: [requestMixin],
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    CreatePaymentForm,
    ValidateOrderConfirmation,
    ViewOrderModal,
  },
  data() {
    return {
      loadingOriginOffice: false,
      loadingOrder: false,
      loadingBuyerType: false,
      loadingPayment: false,
      order: {
        id: null,
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        custom_shipment_address: false,
        date: new Date(),
        destinationOffice: {
          area: {
            id: null,
            code: null,
          },
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
          uplineCode: null,
          uplineName: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
        },
        destinationOfficeType: 'member',
        originOffice: {
          area: {
            id: null,
            code: null,
          },
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
        },
        shipment_type: 'pickup',
        shipmentAddress: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          address: null,
          area: {
            id: null,
            code: null,
          },
        },
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          isPoint: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
          totalWeight: null,
          pointValue: null,
          bonusValue: null,
        },
      ],
      paymentId: null,
      paymentMethods: [
        {
          paymentMethodId: null,
          paymentMethodTypeId: null,
          amount: null,
          officeBankId: null,
          receiptNumber: null,
        },
      ],
      visibleDetail: false,
      visibleValidate: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    loading() {
      return (
        this.loadingBuyerType ||
        this.loadingOriginOffice ||
        this.loadingOrder ||
        this.loadingPayment
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
  },
  methods: {
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, err] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 8,
          'fields[buyer-types]': 'code',
        },
      });

      if (!err && res.data.length) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadOriginOffice() {
      this.loadingOriginOffice = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}`,
        {
          params: {
            include: 'area,current-warehouse',
            'fields[offices]': 'code,name,area,current-warehouse',
            'fields[areas]': 'code',
            'fields[warehouses]': 'code',
          },
        }
      );

      if (!err) {
        const area = this.getSingleIncluded(
          res,
          res.data.relationships.area.data.id
        );
        const warehouse = this.getSingleIncluded(
          res,
          res.data.relationships['current-warehouse'].data.id
        );

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;
        this.order.originOffice.area.id = area.id;
        this.order.originOffice.area.code = area.attributes.code;
        (this.order.originOffice.id = res.data.id),
          (this.order.originOffice.code = res.data.attributes.code),
          (this.order.originOffice.originalCode = res.data.attributes.code),
          (this.order.originOffice.searchCode = res.data.attributes.code),
          (this.order.originOffice.name = res.data.attributes.name),
          (this.order.originOffice.currentWarehouse.id = warehouse.id);
        this.order.originOffice.currentWarehouse.code =
          warehouse.attributes.code;
      }

      this.loadingOriginOffice = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include:
              'area,buyer-type,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details',
            'fields[orders]':
              'createdAt,order_shipment,shipment_address,area,buyer-type,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details',
            'fields[areas]': 'code',
            'fields[buyer-types]': 'code',
            'fields[offices]':
              'code,name,referred_by,referred_by_name,area_code',
            'fields[warehouses]': 'code',
            'fields[order-details]':
              'product_code,product_name,is_point,current_stock,product_qty,product_price,total_price,total_weight,point_value,bonus_value',
          },
        }
      );

      if (!err) {
        const area = this.getSingleIncluded(
          res,
          res.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          res,
          res.data.relationships['buyer-type'].data.id
        );
        const destinationOffice = this.getSingleIncluded(
          res,
          res.data.relationships['destination-office'].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          res,
          res.data.relationships['destination-warehouse'].data.id
        );
        const originOffice = this.getSingleIncluded(
          res,
          res.data.relationships['origin-office'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          res,
          res.data.relationships['origin-warehouse'].data.id
        );

        this.order.id = res.data.id;

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        this.order.custom_shipment_address = false;
        (this.order.date = res.data.attributes.createdAt),
          (this.order.destinationOffice.area.id = null);
        this.order.destinationOffice.area.code = null;

        this.order.destinationOffice.id = destinationOffice.id;
        this.order.destinationOffice.code = destinationOffice.attributes.code;
        this.order.destinationOffice.searchCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.originalCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.name = destinationOffice.attributes.name;
        this.order.destinationOffice.uplineCode =
          destinationOffice.attributes.referred_by;
        this.order.destinationOffice.uplineName =
          destinationOffice.attributes.referred_by_name;

        this.order.destinationOffice.currentWarehouse.id =
          destinationWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          destinationWarehouse.attributes.code;

        this.order.destinationOfficeType = 'member';

        this.order.originOffice.area.id = null;
        this.order.originOffice.area.code = originOffice.attributes.area_code;

        this.order.originOffice.id = originOffice.id;
        this.order.originOffice.code = originOffice.attributes.code;
        this.order.originOffice.originalCode = originOffice.attributes.code;
        this.order.originOffice.searchCode = originOffice.attributes.code;
        this.order.originOffice.name = originOffice.attributes.name;

        this.order.originOffice.currentWarehouse.id = originWarehouse.id;
        this.order.originOffice.currentWarehouse.code =
          originWarehouse.attributes.code;

        this.order.shipment_type = res.data.attributes.order_shipment;

        this.order.shipmentAddress.address =
          destinationOffice.attributes.address ||
          res.data.attributes.shipment_address;

        const orderDetails = this.getIncludedByType(res, 'order-details');

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          isPoint: orderDetail.attributes.is_point,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
          totalWeight: orderDetail.attributes.total_weight,
          pointValue: orderDetail.attributes.point_value,
          bonusValue: orderDetail.attributes.bonus_value,
        }));

        this.orderDetails.push({
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          isPoint: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
          totalWeight: null,
          pointValue: null,
          bonusValue: null,
        });

        this.loadPayment(res.data.id);
      }

      this.loadingOrder = false;
    },
    async loadPayment(id) {
      this.loadingPayment = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${id || this.$route.params.id}/payments`,
        {
          params: {
            'fields[payments]': 'code',
            'page[size]': 1,
          },
        }
      );

      if (!err && res.data.length) {
        this.paymentId = res.data[0].id;

        await this.loadPaymentMethods();
      }

      this.loadingPayment = false;
    },
    async loadPaymentMethods() {
      const [res, err] = await this.request(
        `/api/v1/payments/${this.paymentId}/payment-methods`,
        {
          params: {
            include: 'payment-method-type,office-bank',
            'fields[payment-methods]':
              'payment_amount,receipt_number,payment-method-type,office-bank,payment_method_type',
            'fields[payment-method-types]': 'name',
            'fields[office-banks]': 'bank_name',
          },
        }
      );

      if (!err) {
        const paymentMethods = res.data.filter(
          (paymentMethod) =>
            paymentMethod.attributes.payment_method_type !== 'Cashback'
        );

        if (paymentMethods.length) {
          this.paymentMethods = paymentMethods.map((paymentMethod) => {
            const paymentMethodType =
              paymentMethod.relationships['payment-method-type'].data;
            const officeBank = paymentMethod.relationships['office-bank'].data;

            return {
              paymentMethodId: paymentMethod.id,
              paymentMethodTypeId: paymentMethodType
                ? paymentMethodType.id
                : null,
              amount: toCurrency(paymentMethod.attributes.payment_amount),
              officeBankId: officeBank ? officeBank.id : null,
              receiptNumber: paymentMethod.attributes.receipt_number,
            };
          });
        }
      }
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.loadPayment(order.data.id);

      this.$router.push({
        name: 'transaksi-cashbill.edit',
        params: {
          id: order.data.id,
        },
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.loadOrder();
    } else {
      this.loadOriginOffice();
      this.loadBuyerType();
    }
  },
};
</script>
