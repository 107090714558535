<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <cashbill-summary-widget :meta="summary" />
      <div class="flex flex-grow flex-col gap-4 xl:flex-row xl:justify-end">
        <mitra-period-select
          class="grid gap-4 xl:flex"
          with-label
          v-model="filter.mitra_period_id"
          @change="loadSimpleOrders"
        />
        <base-input label="Status Faktur">
          <base-select
            :shadow="false"
            :options="filterStatusOptions"
            expand
            v-model="filter.status"
            @change="loadSimpleOrders"
          />
        </base-input>
        <base-input
          label="Pencarian"
          type="text"
          :shadow="false"
          fullwidth
          :lg-fullwidth="false"
          :xl-fullwidth="false"
          placeholder="Cari Kode atau Nama"
          v-model="filter.search"
          debounce
          @native-input="loadSimpleOrders"
        />
        <div
          class="flex flex-col gap-2 xl:order-first xl:flex-row xl:gap-4 xl:pt-8"
        >
          <base-checkbox
            label="Aktivasi Mitra Usaha Expired"
            v-model="filter.is_for_office_expiry_activation"
            @change="loadSimpleOrders"
          ></base-checkbox>
          <base-checkbox label="Tambah Point"></base-checkbox>
        </div>
        <base-button
          fullwidth
          :xl-fullwidth="false"
          :to="{ name: 'transaksi-cashbill.tambah' }"
        >
          <Icon icon="heroicons:plus" class="h-4 w-4" />
          Tambah Cashbill
        </base-button>
      </div>
      <datatable
        :columns="tableColumns"
        :total="simpleOrders.meta.page.total"
        :perPage="simpleOrders.meta.page.perPage"
        :currentPage="simpleOrders.meta.page.currentPage"
        compact
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="simpleOrder in simpleOrders.data"
            :key="simpleOrder.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickRow(simpleOrder)"
          >
            <td :class="classes.td">
              <p>
                <span class="font-bold text-gray-900">{{
                  simpleOrder.attributes.origin_code
                }}</span>
                /
                <span>{{ simpleOrder.attributes.destination_code }}</span>
              </p>
              <p class="text-xs">
                {{ simpleOrder.attributes.createdAt | formatDate }}
              </p>
            </td>
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['destination-office'].data.id
                  ).attributes.code
                }}
              </p>
              <p class="text-xs">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['destination-office'].data.id
                  ).attributes.name
                }}
              </p>
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getSingleIncluded(
                  simpleOrders,
                  simpleOrder.relationships.order.data.id
                ).attributes.grand_total_pv | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getSingleIncluded(
                  simpleOrders,
                  simpleOrder.relationships.order.data.id
                ).attributes.total_bonus_value | toCurrency
              }}
            </td>
            <td :class="classes.td">
              <p class="text-gray-900">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['destination-office'].data.id
                  ).attributes.referred_by
                }}
              </p>
              <p class="text-xs">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['destination-office'].data.id
                  ).attributes.referred_by_name
                }}
              </p>
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getSingleIncluded(
                  simpleOrders,
                  simpleOrder.relationships.order.data.id
                ).attributes.final_price | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="
                  simpleOrder.attributes.order_status === 'Bonus Terlaporkan'
                    ? 'green'
                    : 'red'
                "
                >{{
                  simpleOrder.attributes.order_status === 'Bonus Terlaporkan'
                    ? 'Dilaporkan'
                    : 'Belum Dilaporkan'
                }}</base-badge
              >
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge color="indigo">{{
                simpleOrder.attributes.order_status
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <view-order-modal
      label="Cashbill"
      :columns="[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ]"
      :with-shipment="false"
      edit-route-name="transaksi-cashbill.edit"
      :visible="viewModal.visible"
      :order-id="viewModal.orderId"
      @close="viewModal.visible = false"
      @deleted="onRefresh"
      @validated="loadSimpleOrders"
    />
  </base-wrapper>
</template>

<script>
import CashbillSummaryWidget from '@/components/cash-bill/cashbill-summary-widget.vue';
import MitraPeriodSelect from '@/components/period/mitra-period/mitra-period-select.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import { mapGetters } from 'vuex';
import { requestMixin } from '@//mixins/request/request';

export default {
  mixins: [requestMixin],
  components: {
    CashbillSummaryWidget,
    MitraPeriodSelect,
    BaseCheckbox,
    ViewOrderModal,
  },
  data() {
    return {
      filter: {
        is_for_office_expiry_activation: false,
        mitra_period_id: null,
        status: null,
        search: null,
      },
      loading: false,
      simpleOrders: {
        data: [],
        meta: {
          page: {},
        },
      },
      viewModal: {
        visible: false,
        orderId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    filterStatusOptions() {
      return [
        {
          key: 'null',
          value: null,
          label: 'Semua',
        },
        {
          key: 'true',
          value: true,
          label: 'Valid',
        },
        {
          key: 'reported',
          value: 'reported',
          label: 'Dilaporkan',
        },
        {
          key: 'false',
          value: false,
          label: 'Draft',
        },
        {
          key: 'cancel',
          value: 'cancel',
          label: 'Cancel',
        },
        {
          key: 'deleted',
          value: 'deleted',
          label: 'Hapus',
        },
      ];
    },
    filterStatus() {
      if (this.filter.status === null) {
        return {};
      }

      if (this.filter.status === 'deleted') {
        return {
          'filter[only-trahsed]': true,
        };
      }

      if (this.filter.status === 'reported') {
        return {
          'filter[order_status]': 'Bonus Terlaporkan',
        };
      }

      if (this.filter.status === 'cancel') {
        return {
          'filter[order_status]': 'Batal Faktur Gudang,Batal Faktur Kasir',
        };
      }

      if (this.filter.status) {
        return {
          'filter[not-order_status]': 'Draft',
        };
      }

      return {
        'filter[order_status]': 'Draft',
      };
    },
    summary() {
      return {
        totalPrice: 0,
        totalPv: 0,
        totalBv: 0,
      };
    },
    tableColumns() {
      return [
        { id: 'code', name: 'No. Cashbill' },
        { id: 'destination', name: 'Mitra Usaha' },
        { id: 'pv', name: 'PV', theadClass: 'text-right' },
        { id: 'bv', name: 'BV', theadClass: 'text-right' },
        { id: 'upline', name: 'Upline' },
        {
          id: 'total_price',
          name: 'Total Harga Mitra Usaha',
          theadClass: 'text-right',
        },
        {
          id: 'report_status',
          name: 'Status Laporan',
          theadClass: 'text-center',
        },
        { id: 'status', name: 'Status Faktur', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    async loadSimpleOrders(params) {
      this.loading = true;

      const [res, err] = await this.request(`/api/v1/orders`, {
        params: {
          'filter[origin_warehouse_id]': this.me.current_warehouse,
          'filter[mitra_period_uuid]': this.filter.mitra_period_id,
          'filter[search]': this.filter.search,
          'filter[is_for_office_expiry_activation]':
            this.filter.is_for_office_expiry_activation,
          'filter[destination_office_category_id]': 5,
          include: 'destination-office,order',
          'fields[simple-orders]':
            'origin_code,destination_code,createdAt,order_status,destination-office,order',
          'fields[offices]': 'code,name,referred_by,referred_by_name',
          'fields[orders]': 'grand_total_pv,total_bonus_value,final_price',
          'page[size]': 5,
          ...this.filterStatus,
          ...params,
        },
      });

      if (!err) {
        this.simpleOrders = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadSimpleOrders({
        'page[number]': page,
      });
    },
    onClickRow(simpleOrder) {
      this.viewModal.orderId = simpleOrder.relationships.order.data.id;
      this.viewModal.visible = true;
    },
    onRefresh() {
      this.viewModal.visible = false;
      this.loadSimpleOrders();
    },
  },
  created() {
    this.filter.mitra_period_id = this.me.current_period.id;

    this.loadSimpleOrders();
  },
};
</script>
