<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-select
          :options="[
            { key: 'all', value: null, label: 'Semua' },
            { key: 'paid', value: true, label: 'Lunas' },
            { key: 'not_paid', value: false, label: 'Belum Lunas' },
          ]"
          :expand="false"
          :shadow="false"
          v-model="filter.is_paid"
          @change="loadOrders"
        />
        <base-input
          placeholder="Search"
          :shadow="false"
          debounce
          @native-input="loadOrders"
          type="search"
          v-model="filter.search"
        />
      </div>
      <datatable
        :columns="columns"
        :data="orders.data"
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        cursor
        @pagechanged="onPageChanged"
      >
        <template v-slot:tbody="{ classes }">
          <tr
            v-for="(order, index) in orders.data"
            :key="order.id"
            :class="[
              classes.tr,
              index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              'cursor-pointer bg-white hover:bg-green-100',
            ]"
            @click="onViewOrder(order)"
          >
            <td :class="[classes.td]">
              <span class="font-bold text-gray-900">
                {{ order.attributes.origin_code }} </span
              >/
              <span class="text-gray-400">{{
                order.attributes.destination_code
              }}</span>
              <p class="mt-1 text-xs text-gray-500">
                {{ order.attributes.updatedAt | formatDate }}
              </p>
            </td>
            <td :class="classes.td">
              {{
                getSingleIncluded(orders, order.relationships.order.data.id)
                  .attributes.origin_office_code
              }}
            </td>
            <td :class="classes.td">
              {{
                getSingleIncluded(orders, order.relationships.order.data.id)
                  .attributes.origin_warehouse_code
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                Math.max(
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.grand_total_price -
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.payment_amount,
                  0
                ) | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getSingleIncluded(orders, order.relationships.order.data.id)
                  .attributes.grand_total_price | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge :color="order.attributes.is_paid ? 'green' : 'red'">{{
                order.attributes.is_paid ? 'Lunas' : 'Belum Lunas'
              }}</base-badge>
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge color="yellow">{{
                order.attributes.order_status
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      filter: {
        is_paid: false,
        search: null,
      },
      orders: {
        data: [],
        meta: {
          page: {
            total: 0,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    columns() {
      return [
        { id: 'code', name: 'No. Faktur' },
        { id: 'description', name: 'Kode Stockist' },
        { id: 'warehouse_code', name: 'Kode Gudang' },
        {
          id: 'payment_remainder',
          name: 'Sisa Pembayaran',
          theadClass: 'text-right',
        },
        {
          id: 'total_price',
          name: 'Total Penjualan',
          theadClass: 'text-right',
        },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
        { id: 'order_status', name: 'Status Order', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    async loadOrders(params = {}) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 5,
          'filter[order_type]': 'sale',
          'filter[origin_warehouse_id]': this.me.current_warehouse,
          'filter[search]': this.filter.search,
          'filter[is_paid]': this.filter.is_paid,
          'filter[not-order_status]': 'Draft,Batal Faktur Kasir, Batal Faktur Gudang',
          include: 'order',
          'fields[simple-orders]':
            'origin_code,destination_code,updatedAt,is_paid,order_status,order',
          'fields[orders]':
            'origin_office_code,origin_warehouse_code,grand_total_price,payment_amount',
          ...params,
        },
      });

      if (!error) {
        this.orders = res;
      }

      this.loading = false;
    },
    onPageChanged(page) {
      this.loadOrders({
        ...page,
      });
    },
    onViewOrder(order) {
      this.$router.push({
        name: 'kasir.pembayaran.detail',
        params: { id: order.relationships.order.data.id },
      });
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
