<template>
  <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:grid-cols-6">
    <base-card>
      <dt class="text-sm text-gray-700">Total Cash Bill</dt>
      <dd class="mt-1 font-bold text-gray-900">
        {{ meta.totalPrice }}
      </dd>
    </base-card>
    <base-card>
      <dt class="text-sm text-gray-700">Total PV</dt>
      <dd class="mt-1 font-bold text-gray-900">
        {{ meta.totalPv }}
      </dd>
    </base-card>
    <base-card>
      <dt class="text-sm text-gray-700">Total BV</dt>
      <dd class="mt-1 font-bold text-gray-900">
        {{ meta.totalBv }}
      </dd>
    </base-card>
  </div>
</template>

<script>
export default {
  props: {
    meta: Object,
  },
};
</script>
