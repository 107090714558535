var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('create-order-form',{attrs:{"use-origin-office-warehouse":"","with-destination-area":"","with-restock-type":false,"with-upline":""},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.order.destinationOffice.id)?[_c('create-order-detail-form',{attrs:{"order-id":_vm.order.id,"origin-office":{
          id: _vm.order.originOffice.id,
          code: _vm.order.originOffice.code,
        },"destination-office":{
          id: _vm.order.destinationOffice.id,
          code: _vm.order.destinationOffice.code,
        },"origin-warehouse":{
          id: _vm.order.originOffice.currentWarehouse.id,
          code: _vm.order.originOffice.currentWarehouse.code,
        },"buyer-type":_vm.order.buyerType,"area":_vm.order.area,"columns":[
          'code',
          'name',
          'point',
          'stock',
          'qty',
          'price',
          'total_price',
          'action',
          'total_weight',
          'point_value',
          'bonus_value',
        ],"total-price-label":"","create-order-attributes":{
          order_shipment: _vm.order.shipment_type,
          order_type: 'sale',
          destination_warehouse_id:
            _vm.order.destinationOffice.currentWarehouse.id,
          ...(_vm.order.custom_shipment_address
            ? {
                destination_address: {
                  province_id: _vm.order.shipmentAddress.province.id,
                  city_id: _vm.order.shipmentAddress.city.id,
                  district_id: _vm.order.shipmentAddress.district.id,
                  village_id: _vm.order.shipmentAddress.village.id,
                  address: _vm.order.shipmentAddress.address,
                },
              }
            : {}),
        }},on:{"order-created":_vm.onOrderCreated},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}),_c('create-payment-form',{attrs:{"order-id":_vm.order.id,"payment-id":_vm.paymentId,"with-validate-button":false,"with-summary":false,"sync":"","auto-save":""},model:{value:(_vm.paymentMethods),callback:function ($$v) {_vm.paymentMethods=$$v},expression:"paymentMethods"}}),_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-button',{attrs:{"disabled":_vm.totalPrice < 1},on:{"click":function($event){_vm.visibleValidate = true}}},[_vm._v("Siap Validasi")]),_c('base-button',{attrs:{"color":"danger","to":{ name: 'transaksi-cashbill' }}},[_vm._v("Selesai")])],1)]:_vm._e(),_c('validate-order-confirmation',{attrs:{"order-id":_vm.order.id,"visible":_vm.visibleValidate},on:{"close":function($event){_vm.visibleValidate = false},"confirmed":function($event){_vm.visibleDetail = true}}}),_c('view-order-modal',{attrs:{"label":"Cashbill","columns":[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ],"with-shipment":false,"visible":_vm.visibleDetail,"order-id":_vm.order.id},on:{"close":function($event){return _vm.$router.push({ name: 'transaksi-cashbill' })},"validated":function($event){return _vm.$router.push({ name: 'transaksi-cashbill' })}}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }