<template>
  <base-wrapper :loading="loading">
    <div class="space-y-6">
      <payment-order-detail-card :order="order" />

      <template v-if="pageLoaded">
        <create-payment-form
          v-if="needCreatePayment"
          :min-amount="order.data.attributes.grand_total_price"
          :paid="paidAmount"
          @save="onCreatePayment"
        />

        <payment-list
          :payments="payments"
          :order-price="order.data.attributes.grand_total_price"
          @reload="loadPayments"
          @verified="onVerifiedPayment"
        />

        <div :class="['flex', order.data.attributes.is_valid_for_packing ? 'justify-end' : 'justify-between']">
          <base-button v-if="!order.data.attributes.is_valid_for_packing" color="white" @click="visibleDeleteConfirm = true"
            >Batalkan Faktur Penjualan</base-button
          >
          <base-button :to="{ name: 'kasir.pembayaran' }" color="white"
            >Kembali</base-button
          >
        </div>
      </template>
    </div>

    <base-confirm
      message="Sudah ada faktur pembayaran. Anda yakin ingin membatalkan?"
      :visible="visibleDeleteConfirm"
      @close="visibleDeleteConfirm = false"
      @confirmed="onConfirmDelete"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import PaymentOrderDetailCard from '@/components/payment/payment-order-detail-card.vue';
import CreatePaymentForm from '@/components/payment/create-payment-form.vue';
import PaymentList from '@/components/payment/payment-list.vue';
import BaseConfirm from '@/components/base/BaseConfirm.vue';

export default {
  components: {
    PaymentOrderDetailCard,
    CreatePaymentForm,
    PaymentList,
    BaseConfirm,
  },
  mixins: [requestMixin],
  data() {
    return {
      order: {
        data: {
          attributes: {},
          relationships: {
            'destination-office': {
              data: {
                id: null,
              },
            },
          },
        },
        included: [],
      },
      payments: {
        data: [],
      },
      loadingOrder: true,
      loadingCreatePayment: false,
      loadingPayments: false,
      loadingCancel: false,
      pageLoaded: false,
      visibleDeleteConfirm: false,
    };
  },
  computed: {
    loading() {
      return (
        this.loadingOrder ||
        this.loadingCreatePayment ||
        this.loadingPayments ||
        this.loadingCancel
      );
    },
    orderDestinationOffice() {
      return this.getSingleIncluded(
        this.order,
        this.order.data.relationships['destination-office'].data.id
      );
    },
    needCreatePayment() {
      if (!this.payments.data.length) {
        return true;
      }

      if (
        this.payments.data.some((payment) => !payment.attributes.is_verified)
      ) {
        return false;
      }

      if (!this.order.data.attributes.is_paid) {
        return true;
      }

      return false;
    },
    paidAmount() {
      return this.payments.data.reduce(
        (total, payment) => total + payment.attributes.total_amount,
        0
      );
    },
  },
  methods: {
    async createPaymentMethods(payment, paymentMethods) {
      const requests = paymentMethods.map((paymentMethod) => {
        return this.request(`/api/v1/payment-methods`, {
          method: 'post',
          data: {
            data: {
              type: 'payment-methods',
              attributes: {
                payment_amount: Number(
                  paymentMethod.amount.replace(/\D/gi, '')
                ),
                receipt_number: paymentMethod.receipt_number,
              },
              relationships: {
                payment: {
                  data: {
                    type: 'payments',
                    id: payment.data.id,
                  },
                },
                'payment-method-type': {
                  data: {
                    type: 'payment-method-types',
                    id: paymentMethod.paymentMethodTypeId,
                  },
                },
                ...(paymentMethod.officeBankId
                  ? {
                      'office-bank': {
                        data: {
                          type: 'office-banks',
                          id: paymentMethod.officeBankId,
                        },
                      },
                    }
                  : {}),
              },
            },
          },
        });
      });

      await Promise.all(requests);
    },
    async loadOrder() {
      this.loadingOrder = true;

      const orderId = this.$route.params.id;

      const [res, error] = await this.request(`/api/v1/orders/${orderId}`, {
        params: {
          'fields[orders]':
            'origin_code,origin_warehouse_code,area_code,grand_total_price,order_shipment,is_paid,is_valid_for_packing,destination-office',
          'fields[offices]': 'office_type,code,name,temp_code,is_active',
          include: 'destination-office',
        },
      });

      if (!error) {
        this.order = res;
      }

      this.loadingOrder = false;
    },
    async loadPayments() {
      this.loadingPayments = true;

      const orderId = this.$route.params.id;

      const [res, error] = await this.request(
        `/api/v1/orders/${orderId}/payments`,
        {
          params: {
            'fields[payments]':
              'is_verified,total_amount,code,destination_office_code,origin_warehouse_code,state,createdAt',
          },
        }
      );

      if (!error) {
        this.payments = res;
      }

      this.loadingPayments = false;
    },
    async onConfirmDelete() {
      this.loadingCancel = true;

      const [, error] = await this.request(
        `/api/v1/orders/${this.order.data.id}/-actions/cancelPayment`,
        {
          method: 'patch',
          data: {
            cancel_payment_description: 'Cancel',
          },
        }
      );

      if (!error) {
        this.$router.push({ name: 'kasir.pembayaran' });
      }

      this.loadingCancel = false;
    },
    async onCreatePayment(paymentMethods) {
      this.loadingCreatePayment = true;

      const [payment, error] = await this.request(`/api/v1/payments`, {
        method: 'post',
        data: {
          data: {
            type: 'payments',
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.order.data.id,
                },
              },
            },
          },
        },
      });

      if (!error) {
        await this.createPaymentMethods(payment, paymentMethods);

        this.loadPayments();
      }

      this.loadingCreatePayment = false;
    },
    onVerifiedPayment() {
      this.loadOrder();
      this.loadPayments();
    },
  },
  async created() {
    await Promise.all([this.loadOrder(), this.loadPayments()]);

    this.pageLoaded = true;
  },
};
</script>
